import { Box, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { forgotPassword } from '../core/cognito'
import { BCButton } from './components/Button'
import { EmailSentCard } from './components/EmailSentCard'
import { ErrorContainer } from './components/ErrorContainer'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)

      try {
        await forgotPassword(values.email)
        setHasErrors(false)
        setLoading(false)
      } catch (error) {
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        if (error instanceof Error) {
          setStatus(error.message)
        } else {
          setStatus('The login detail is incorrect')
        }
      }
    },
  })

  return (
    <Box
      component='form'
      noValidate
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        p: '20px',
      }}
    >
      {hasErrors !== false && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            pb: '10px',
          }}
        >
          <Typography variant='h4' sx={{ mt: '20px' }}>
            Forgot Password?
          </Typography>
          <Typography variant='body1' fontWeight={500} color='#a1a5b7'>
            Enter your email to reset password.
          </Typography>
        </Box>
      )}

      {/* begin::Title */}
      {hasErrors === true && (
        <ErrorContainer>
          Sorry, looks like there are some errors detected, please try again.
        </ErrorContainer>
      )}

      {hasErrors === false && (
        <EmailSentCard email={formik.values.email} type='password reset link' />
      )}
      {/* end::Title */}

      {hasErrors !== false && (
        <>
          <TextField
            autoFocus
            autoComplete='off'
            label='Email'
            variant='outlined'
            fullWidth
            {...formik.getFieldProps('email')}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={(formik.touched.email && formik.errors.email) || ' '}
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <BCButton
              fullWidth
              inProgress={loading}
              disabled={loading || !formik.isValid || !formik.dirty}
              label=''
              type='submit'
              sx={{ flexGrow: 1 }}
            >
              Send verification link
            </BCButton>
            <Link to='/auth/login' tabIndex={-1}>
              <BCButton variant='text' type='button'>
                Cancel
              </BCButton>
            </Link>
          </Box>
        </>
      )}
    </Box>
  )
}
