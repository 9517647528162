/* eslint-disable react/jsx-no-target-blank */
import { Box, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'

import { useAuth } from '../core/Auth'
import { AuthModel } from '../core/_models'
import { getUserByToken } from '../core/_requests'
import { BCButton } from './components/Button'
import { ErrorContainer } from './components/ErrorContainer'

const initialValues = {
  code: '',
}

const registrationSchema = Yup.object().shape({
  code: Yup.string().length(6, 'Must be exactly 6 digits').required('Code is required'),
})

interface Props {
  mfaConfirm: (code: string) => Promise<AuthModel>
}

export const MFA: FC<Props> = ({ mfaConfirm }) => {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setSubmitting(true)
      try {
        const authData = await mfaConfirm(values.code)

        saveAuth(authData)
        const { data: user } = await getUserByToken()
        setCurrentUser(user)
      } catch (err) {
        if (err instanceof Error) {
          return setStatus(err.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (formik.values.code.length === 6) {
      formik.submitForm()
    }
  }, [formik.values.code])

  return (
    <Box
      component='form'
      noValidate
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {formik.status && <ErrorContainer>{formik.status}</ErrorContainer>}

      <Typography variant='h4' sx={{ textAlign: 'center', p: '20px' }}>
        Enter your MFA code here
      </Typography>

      <Typography variant='body1' color='#a1a5b7' fontWeight={500}>
        We sent 6-digit code to your phone number
      </Typography>

      <TextField
        autoFocus
        variant='outlined'
        fullWidth
        label='Code'
        {...formik.getFieldProps('code')}
        error={formik.touched.code && Boolean(formik.errors.code)}
        helperText={(formik.touched.code && formik.errors.code) || ' '}
      />

      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <BCButton
          fullWidth
          disabled={loading || !formik.isValid || !formik.dirty}
          type='submit'
          inProgress={loading}
        >
          Submit
        </BCButton>

        <BCButton onClick={() => window.location.reload()} variant='text'>
          Cancel
        </BCButton>
      </Box>
      {/* end::Form group */}
    </Box>
  )
}
