import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from 'src/_metronic/assets/ts/_utils'
import { WithChildren } from 'src/_metronic/helpers'
import { MasterLayout } from 'src/_metronic/layout/MasterLayout'
import { UserRole } from 'src/app/modules/admin/core/_models'
import { useAuth } from 'src/app/modules/auth'

import { ProjectsWrapper } from '../pages/projects/ProjectsWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/admin/UsersPage'))
  const TasksPage = lazy(() => import('../modules/admin/TasksPage'))
  const ProjectDetailPage = lazy(() => import('../modules/projects/ProjectDetailPage'))
  const ApplicationManagementPage = lazy(
    () => import('../modules/applications/ApplicationManagementPage'),
  )
  const OrganizationManagementPage = lazy(
    () => import('../modules/applications/OrganizationManagementPage'),
  )
  const JurisdictionManagementPage = lazy(
    () => import('../modules/applications/JurisdictionManagementPage'),
  )
  const InternalAppManagementPage = lazy(
    () => import('../modules/applications/InternalAppManagementPage'),
  )

  const ApplicationReview = lazy(() => import('../modules/applications/ApplicationReview'))

  const PermityPage = lazy(() => import('../applications/permity/router/index'))
  const PermityAdminManagementPage = lazy(() => import('../modules/applications/PermityAdmin'))
  const FileRulesetManagement = lazy(() => import('../modules/applications/FileRulesetManagement'))

  const { currentUser } = useAuth()

  console.log(currentUser?.role)

  return (
    <Routes>
      {/* Redirect to Dashboard after success login/registartion */}
      <Route path='auth/*' element={<Navigate to='/' />} />

      {currentUser?.role === UserRole.APPLICANT && (
        <Route
          path='*'
          element={
            <SuspensedView>
              <PermityPage />
            </SuspensedView>
          }
        />
      )}

      {currentUser?.role !== UserRole.APPLICANT && (
        <Route element={<MasterLayout />}>
          {currentUser?.role === UserRole.BC_REVIEWER && (
            <Route
              path='admin/apps/*'
              element={
                <SuspensedView>
                  <InternalAppManagementPage />
                </SuspensedView>
              }
            />
          )}
          {currentUser?.role !== UserRole.BC_REVIEWER && (
            <>
              <Route path='projects' element={<ProjectsWrapper />} />
              <Route
                path='projects/:id/*'
                element={
                  <SuspensedView>
                    <ProjectDetailPage />
                  </SuspensedView>
                }
              />
              <Route
                path='applications/*'
                element={
                  <SuspensedView>
                    <ApplicationManagementPage />
                  </SuspensedView>
                }
              />

              <Route
                path='app_review/:applicationId'
                element={
                  <SuspensedView>
                    <ApplicationReview />
                  </SuspensedView>
                }
              />
            </>
          )}

          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />

          <Route
            path='organizations/*'
            element={
              <SuspensedView>
                <OrganizationManagementPage />
              </SuspensedView>
            }
          />

          {currentUser?.isAdmin && (
            <>
              <Route
                path='file_rulesets/*'
                element={
                  <SuspensedView>
                    <FileRulesetManagement />
                  </SuspensedView>
                }
              />
              <Route
                path='admin/apps/*'
                element={
                  <SuspensedView>
                    <InternalAppManagementPage />
                  </SuspensedView>
                }
              />
              <Route
                path='admin/permity_apps/*'
                element={
                  <SuspensedView>
                    <PermityAdminManagementPage />
                  </SuspensedView>
                }
              />

              <Route
                path='jurisdictions/:jurisdictionId/*'
                element={
                  <SuspensedView>
                    <JurisdictionManagementPage />
                  </SuspensedView>
                }
              />
              <Route
                path='crafted/pages/profile/*'
                element={
                  <SuspensedView>
                    {/* <ProjectDetailPage /> */}

                    <ProfilePage />
                  </SuspensedView>
                }
              />
              <Route
                path='crafted/pages/wizards/*'
                element={
                  <SuspensedView>
                    <WizardsPage />
                  </SuspensedView>
                }
              />
              <Route
                path='crafted/widgets/*'
                element={
                  <SuspensedView>
                    <WidgetsPage />
                  </SuspensedView>
                }
              />
              <Route
                path='apps/chat/*'
                element={
                  <SuspensedView>
                    <ChatPage />
                  </SuspensedView>
                }
              />
              <Route
                path='apps/user-management/*'
                element={
                  <SuspensedView>
                    <UsersPage />
                  </SuspensedView>
                }
              />
              <Route
                path='/task-management/*'
                element={
                  <SuspensedView>
                    <TasksPage />
                  </SuspensedView>
                }
              />
              {/* Page Not Found */}
            </>
          )}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      )}
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
