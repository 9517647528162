import clsx from 'clsx'
import { FC, useEffect } from 'react'
import { useLocation } from 'react-router'

import { DrawerComponent } from '../../assets/ts/components'
import { WithChildren } from '../../helpers'
import { useLayout } from '../core'

const Content: FC<WithChildren> = ({ children }) => {
  const { classes, config } = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div
      id='kt_content_container'
      className={clsx(
        classes.contentContainer.join(' '),
        'flex-grow-1',
        'flex-shrink-1',
        config.content.layout === 'full-height' && 'p-0',
      )}
      style={{ minHeight: 0 }}
    >
      {children}
    </div>
  )
}

export { Content }
