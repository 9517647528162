import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoRefreshToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js'

import { AuthModel } from './_models'

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID || 'us-west-1_q5XUZ70vM',
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '6vd5l1ge4ge9oenhcrh8hhqtuo',
})

interface AuthResponse {
  auth?: AuthModel
  setupNewPassword?: (password: string) => Promise<AuthModel>
  mfaConfirm?: (code: string) => Promise<AuthModel>
}

export const authenticate = async (username: string, password: string): Promise<AuthResponse> => {
  const authenticationData = {
    Username: username,
    Password: password,
  }
  const authenticationDetails = new AuthenticationDetails(authenticationData)

  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        resolve({
          auth: {
            accessToken: result.getAccessToken().getJwtToken(),
            refreshToken: result.getRefreshToken().getToken(),
          },
        })
      },
      onFailure: (err) => {
        reject(err)
      },
      mfaRequired: () => {
        resolve({
          mfaConfirm: async (code: string) =>
            new Promise((resolve, reject) => {
              cognitoUser.sendMFACode(code, {
                onSuccess: (result) => {
                  resolve({
                    accessToken: result.getAccessToken().getJwtToken(),
                    refreshToken: result.getRefreshToken().getToken(),
                  })
                },
                onFailure: (err) => {
                  reject(err)
                },
              })
            }),
        })
      },
      newPasswordRequired: () => {
        resolve({
          setupNewPassword: async (password: string) =>
            new Promise((resolve, reject) => {
              cognitoUser.completeNewPasswordChallenge(
                password,
                {},
                {
                  onSuccess: (result) => {
                    resolve({
                      accessToken: result.getAccessToken().getJwtToken(),
                      refreshToken: result.getRefreshToken().getToken(),
                    })
                  },
                  onFailure: (err) => {
                    reject(err)
                  },
                },
              )
            }),
        })
      },
    })
  })
}

export const getAccessByRefreshToken = async (refreshToken: string): Promise<AuthModel> => {
  const user = userPool.getCurrentUser()
  const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken })
  return new Promise((resolve, reject) => {
    user?.refreshSession(cognitoRefreshToken, (err, session: CognitoUserSession) => {
      if (err) {
        reject(err)
      } else {
        resolve({
          accessToken: session.getAccessToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(),
        })
      }
    })
  })
}

export const signUp = (username: string, password: string): Promise<CognitoUser | undefined> => {
  return new Promise((resolve, reject) => {
    userPool.signUp(username, password, [], [], (err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(result?.user)
      }
    })
  })
}

export const confirmSignUp = (username: string, code: string): Promise<CognitoUser | undefined> => {
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  })

  return new Promise((resolve, reject) => {
    user.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(result?.user)
      }
    })
  })
}

export const resendVerificationCode = (username: string): Promise<CognitoUser | undefined> => {
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  })

  return new Promise((resolve, reject) => {
    user.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(result?.user)
      }
    })
  })
}

export const forgotPassword = (username: string): Promise<CognitoUser | undefined> => {
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  })

  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess: (result) => {
        resolve(result?.user)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export const resetPassword = (
  username: string,
  code: string,
  newPassword: string,
): Promise<string> => {
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  })

  return new Promise((resolve, reject) => {
    user.confirmPassword(code, newPassword, {
      onSuccess: (result) => {
        resolve(result)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}
