/* eslint-disable react/jsx-no-target-blank */
import { Box, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { signUp } from '../core/cognito'
import { passwordValidator, confirmPasswordValidator } from '../core/validations'
import { AcceptTerms } from './components/AcceptTerms'
import { BCButton } from './components/Button'
import { EmailSentCard } from './components/EmailSentCard'
import { ErrorContainer } from './components/ErrorContainer'
import { PasswordBar } from './components/PasswordBar'
import { PasswordField } from './components/PasswordField'

const initialValues = {
  password: '',
  changepassword: '',
  acceptTerms: false,
  email: '',
}

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: passwordValidator,
  changepassword: confirmPasswordValidator,
  acceptTerms: Yup.bool().oneOf([true], 'You must accept the Terms of Service and Privacy Policy'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setSubmitting(true)
      try {
        await signUp(values.email, values.password)
        return setEmailSent(true)
      } catch (err) {
        if (err instanceof Error) {
          return setStatus(err.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return emailSent ? (
    <Box>
      <EmailSentCard email={formik.values.email} type='sign-in link' />
    </Box>
  ) : (
    <Box
      component='form'
      noValidate
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        p: '20px',
      }}
    >
      {(formik.status || (formik.touched.acceptTerms && formik.errors.acceptTerms)) && (
        <ErrorContainer>{formik.status || formik.errors.acceptTerms}</ErrorContainer>
      )}

      <Box sx={{ textAlign: 'center', p: '20px' }}>
        <Typography variant='h4'>Sign Up</Typography>
      </Box>

      <TextField
        autoFocus
        label='Email'
        variant='outlined'
        autoComplete='one-time-code'
        InputProps={{
          autoComplete: 'one-time-code',
        }}
        fullWidth
        {...formik.getFieldProps('email')}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={(formik.touched.email && formik.errors.email) || ' '}
      />

      <Box
        sx={{
          display: 'grid',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gap: '5px',
          }}
        >
          <PasswordField
            label='Password'
            variant='outlined'
            autoComplete='off'
            fullWidth
            {...formik.getFieldProps('password')}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={(formik.touched.password && formik.errors.password) || ' '}
          />
          <PasswordBar password={formik.values.password} />
        </Box>
        <Typography variant='body1' color='#a1a5b7' fontWeight={500}>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </Typography>
      </Box>

      <PasswordField
        label='Confirm Password'
        variant='outlined'
        fullWidth
        {...formik.getFieldProps('changepassword')}
        error={formik.touched.changepassword && Boolean(formik.errors.changepassword)}
        helperText={(formik.touched.changepassword && formik.errors.changepassword) || ' '}
      />

      <AcceptTerms {...formik.getFieldProps('acceptTerms')} checked={formik.values.acceptTerms} />

      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <BCButton
          fullWidth
          inProgress={loading}
          disabled={loading || !formik.isValid || !formik.dirty}
          type='submit'
          sx={{ flexGrow: 1 }}
        >
          Continue
        </BCButton>
        <Link to='/auth/login' tabIndex={-1}>
          <BCButton variant='text'>Cancel</BCButton>
        </Link>
      </Box>
      {/* end::Form group */}
    </Box>
  )
}
