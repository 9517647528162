import { BCID } from 'app/modules/projects/core/_models'

export interface ReportCreate {
  projectId: number
  filePath?: string
  reportStatus: string
  createdBy: number
  createdAt: string
  properties?: {
    documentId: BCID
  }
}

export interface Report extends ReportCreate {
  id: number
  approvedBy?: number
  deletedAt?: string
}

export type ReportStatus = {
  title: string
  color: string
}

export const REPORT_STATUS_INITIAL = 'I'
export const REPORT_STATUS_REQUESTED = 'R'
export const REPORT_STATUS_GENERATED = 'G'
export const REPORT_STATUS_APPROVED = 'A'
export const REPORT_STATUS_DOWNLOADED = 'D'

export const statuses: { [key: string]: ReportStatus } = {
  [REPORT_STATUS_INITIAL]: {
    title: 'Initial',
    color: 'primary',
  },
  [REPORT_STATUS_REQUESTED]: {
    title: 'Requested',
    color: 'warning',
  },
  [REPORT_STATUS_GENERATED]: {
    title: 'Generated',
    color: 'primary',
  },
  [REPORT_STATUS_APPROVED]: {
    title: 'Approved',
    color: 'success',
  },
  [REPORT_STATUS_DOWNLOADED]: {
    title: 'Downloaded',
    color: 'info',
  },
}
export interface SegmentUpdate {
  content?: string
  label?: number
  model_version?: string
  n?: number
}

export interface Segment extends SegmentUpdate {
  id: number
  prediction: number
}

export interface User {
  id: BCID
  email: string
  isAdmin: boolean
  createdAt: string
  deletedAt: string | null
  isPasswordSet: boolean
  role: UserRole
  firstName?: string
  lastName?: string
}

export interface UserCreate {
  email: string
  role: UserRole
}

export interface UserUpdate {
  isAdmin?: boolean
  deletedAt?: string | null
  password?: string | null
  role?: UserRole
  firstName?: string
  lastName?: string
}

export enum UserRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  SYSTEM = 'SYSTEM',
  APP_VIEWER = 'APP_VIEWER',
  APPLICANT = 'APPLICANT',
  BC_REVIEWER = 'BC_REVIEWER',
}

export const USER_LABELS = {
  [UserRole.ADMIN]: 'Admin',
  [UserRole.MEMBER]: 'Member',
  [UserRole.SYSTEM]: 'System',
  [UserRole.APP_VIEWER]: 'Application Viewer',
  [UserRole.APPLICANT]: 'Applicant',
  [UserRole.BC_REVIEWER]: 'BC Reviewer',
}
