import { Box, Link, Typography } from '@mui/material'
import { Outlet, Route, Routes, useSearchParams } from 'react-router-dom'

import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Confirm } from './pages/Confirm'
import { ForgotPassword } from './pages/ForgotPassword'
import { Landing } from './pages/Landing'
import { Login } from './pages/Login'
import { Registration } from './pages/Registration'
import { Reset } from './pages/Reset'

const AuthLayout = () => {
  const [searchParams] = useSearchParams()
  const location = window.location

  // Initialize default values
  const termsUrl = 'https://buildcheck.ai/BuildCheck_Terms_and_Conditions.pdf'
  const privacyUrl = 'https://buildcheck.ai/BuildCheck_Privacy_Policy.pdf'
  const logoUrl = '/media/logos/default.png?v1'
  const headerText = 'From application to approval'
  const logoHeight = '50px'
  const gap = '15px'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        // flex: '1 1 auto',
        minHeight: '100vh',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          width: '460px',
          minWidth: '350px',
          display: 'grid',
          gridTemplateRows: '1fr auto',
        }}
      >
        <Box
          sx={{
            p: '30px',
            display: 'grid',
            gridTemplateRows: '1fr 5fr',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap,
            }}
          >
            <a href='#'>
              <img alt='Logo' src={toAbsoluteUrl(logoUrl)} style={{ height: logoHeight }} />
            </a>
            <Typography variant='h6' fontWeight={500}>
              {headerText}
            </Typography>
          </Box>
          <Outlet />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            alignItems: 'center',
            p: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              mb: '5px',
            }}
          >
            <Link href={termsUrl} target='_blank' rel='noreferrer' tabIndex={-1}>
              Terms of Service
            </Link>
            <Link href={privacyUrl} target='_blank' rel='noreferrer' tabIndex={-1}>
              Privacy Policy
            </Link>
          </Box>
          <Typography variant='body1' fontWeight={400} color='#a1a5b7'>
            © 2024 BuildCheck AI, Inc.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          minWidth: 0,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Landing />
      </Box>
    </Box>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='confirm' element={<Confirm />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset' element={<Reset />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
