import { createContext, FC, useCallback, useContext } from 'react'

import { WithChildren } from 'src/_metronic/helpers'

import { ConfirmationModal } from '../components/ConfirmationModal'
import { useModalWithValue } from '../hooks'

interface ConfirmationDialogState {
  openDialog: (config: Config) => void
}

const initialState = {
  openDialog: () => undefined,
}

interface Config {
  onSubmit?: () => void
  onCancel?: () => void
  submitText?: string
  cancelText?: string
  icon?: string
  bodyText?: string | JSX.Element
}

const ConfirmationDialogContext = createContext<ConfirmationDialogState>(initialState)

export const ConfirmationDialogProvider: FC<WithChildren> = ({ children }) => {
  const [modalState, openDialog, closeDialog] = useModalWithValue<Config>()

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      {modalState && <ConfirmationModal onClose={closeDialog} {...modalState} />}
      {children}
    </ConfirmationDialogContext.Provider>
  )
}

export const useConfirmationDialog = (baseConfig: Config) => {
  const { openDialog: baseOpenDialog } = useContext(ConfirmationDialogContext)

  const openDialog = useCallback(
    (config: Config = {}) => baseOpenDialog({ ...defaultConfig, ...baseConfig, ...config }),
    [baseConfig],
  )

  return {
    openDialog,
  }
}

const defaultConfig: Config = {
  icon: 'bi bi-exclamation-circle text-warning',
  submitText: 'Yes, delete',
  cancelText: 'No, cancel',
  bodyText: 'Are you sure you want to delete?',
}

export const useConfirmationDeleteDialog = (baseConfig: Config = {}) => {
  return useConfirmationDialog({ ...defaultConfig, ...baseConfig })
}
