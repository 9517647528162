import { Box, Typography, Link as MuiLink } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  email: string
  type?: string
}
export const EmailSentCard: FC<Props> = ({ email, type = 'link' }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      m: '20px',
      p: '20px',
      boxShadow: '0 4px 25px 0 rgba(41,42,51,.1)',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 24 24'
        fill='none'
        stroke='#bdbdc0'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'></path>
        <polyline points='22,6 12,13 2,6'></polyline>
      </svg>
      <Typography variant='h4'>Check Your Email</Typography>
    </Box>
    <Typography variant='body1' fontWeight={400}>
      We sent a <b>{type}</b> to {email}. This link expires in one hour and can only be used once.
    </Typography>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <Typography variant='body1' fontWeight={700}>
        Didn't receive the email?
      </Typography>
      <Typography variant='body1' fontWeight={400}>
        Look in your email junk folder. If it's not there...
      </Typography>
    </Box>

    <Box component='ul'>
      <li>
        <MuiLink component={Link} to='' tabIndex={-1} onClick={() => window.location.reload()}>
          Go back and enter your email address again
        </MuiLink>
      </li>
      <li>
        <Typography variant='body1' fontWeight={400}>
          <MuiLink href='mailto:support@buildcheck.ai'>Email our support team</MuiLink> if you are
          still having trouble
        </Typography>
      </li>
    </Box>
  </Box>
)
