import { WithChildren } from '_metronic/helpers'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useState, ReactNode } from 'react'

import { LayoutSplashScreen } from 'src/_metronic/layout/core'
import { useAuth } from 'src/app/modules/auth'

const clientSideID = process.env.REACT_APP_LD_CLIENT_ID || '6478e738b8406612e9a52e86'

const DefaultProvider = ({ children }: { children: ReactNode }) => <>{children}</>

interface Context {
  Provider?: ({ children }: { children: ReactNode }) => JSX.Element
}

/**
 * Feature toggles provider
 *
 * Usage example
 *
 * export const Component: FC () => {
 *   const flags = useFlags()
 *
 *   if (flags.bc777) {
 *    return <div>New functionality</div>
 *   } else {
 *     return <div>Old functionality</div>
 *   }
 * }
 *
 * Make sure you flag is exposed to client side SDKs
 */
export const FTProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth()
  const [context, setContext] = useState<Context>({ Provider: undefined })

  useEffect(() => {
    const context = currentUser
      ? {
          key: currentUser.id,
          email: currentUser.email,
          kind: 'user',
          id: currentUser.id,
          created_at: currentUser.createdAt,
          is_admin: currentUser.isAdmin,
        }
      : {
          kind: 'user',
          anonymous: true,
          key: 'anonymous',
        }

    asyncWithLDProvider({
      clientSideID,
      context,
      options: {
        streaming: false,
      },
    })
      .then((Provider) => setContext({ Provider }))
      .catch(() => setContext({ Provider: DefaultProvider }))
  }, [currentUser])

  return context.Provider ? <context.Provider>{children}</context.Provider> : <LayoutSplashScreen />
}
