import { Box } from '@mui/material'
import { FC, useMemo } from 'react'

interface Props {
  password: string
}

export const PasswordBar: FC<Props> = ({ password }) => {
  const passwordCompletness = useMemo(
    () =>
      [
        password.match(/[A-Z]/),
        password.match(/\d/),
        password.match(/[!@#$%^&*(),.?":{}|<>[\]]/),
        password.length > 7,
      ].filter(Boolean).length,
    [password],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '2px',
      }}
    >
      <Box
        sx={{
          height: '5px',
          borderRadius: '5px',
          flexGrow: 1,
          backgroundColor: passwordCompletness > 0 ? '#34c38f' : '#d1d3e0',
        }}
      ></Box>
      <Box
        sx={{
          height: '5px',
          borderRadius: '5px',
          flexGrow: 1,
          backgroundColor: passwordCompletness > 1 ? '#34c38f' : '#d1d3e0',
        }}
      ></Box>
      <Box
        sx={{
          height: '5px',
          borderRadius: '5px',
          flexGrow: 1,
          backgroundColor: passwordCompletness > 2 ? '#34c38f' : '#d1d3e0',
        }}
      ></Box>
      <Box
        sx={{
          height: '5px',
          borderRadius: '5px',
          flexGrow: 1,
          backgroundColor: passwordCompletness > 3 ? '#34c38f' : '#d1d3e0',
        }}
      ></Box>
    </Box>
  )
}
