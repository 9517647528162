import * as Yup from 'yup'

export const passwordValidator = Yup.string()
  .matches(/^[\S]+/, 'Password cannot start with space')
  .matches(/^[\S]+$/, 'Password cannot end with space')
  .matches(/[a-z]/, 'At least one lowercase letter')
  .matches(/[A-Z]/, 'At least one uppercase letter')
  .matches(/\d/, 'At least one number')
  .matches(
    /[!@#$%^&*(),.?":{}|<>[\]]/,
    'At least one special character (symbols other than letters and numbers)',
  )
  .min(8, 'Minimum 8 symbols')
  .max(50, 'Maximum 50 symbols')
  .required('Password is required')

export const confirmPasswordValidator = Yup.string()
  .min(8, 'Minimum 8 symbols')
  .max(50, 'Maximum 50 symbols')
  .required('Password confirmation is required')
  .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match")
