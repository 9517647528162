import axios from 'axios'

import { Document } from 'app/modules/projects/core/_models'

import {
  BCID,
  Organization,
  OrganizationCreate,
  OrganizationUpdate,
  OrganizationUser,
  OrganizationUserCreate,
  Jurisdiction,
  JurisdictionCreate,
  JurisdictionUpdate,
  ApplicationCreate,
  Application,
  ApplicationExtended,
  ApplicationListItem,
  AppReport,
  DocumentCreate,
  Comment,
  User,
  Template,
  TemplateCreate,
  ApplicationTypeCreate,
  ApplicationTypeUpdate,
  ApplicationType,
  InternalApp,
  WooStatus,
  ExportDetails,
  ExternalAppCreate,
  AppFeedback,
  AppFeedbackCreate,
  OrganizationVariables,
  OrganizationVariablesUpdate,
  ApplicationRun,
  RunComment,
  RunDocumentCommentCreate,
  AppRunPredictions,
  PermityAppCreate,
  AppRequirement,
  AppRequirementUpsert,
  Subscription,
  Expense,
  Event,
  TemplateWithPages,
  FileRuleset,
  FileRulesetCreate,
  FileRulesetUpdate,
  CheckResponseStatus,
  DocumentTypeDetail,
  ApplicationRunWithTrafficLights,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ORGANIZATIONS_URL = `${API_URL}/v1/organizations`
const APPLICATIONS_URL = `${API_URL}/v1/applications`
const APPLICATIONS_URL_V2 = `${API_URL}/v2/applications`
const RUNS_URL = `${API_URL}/v1/runs`
const FILES_URL = `${API_URL}/v1/files`
const APP_REPORT_URL = `${API_URL}/v1/app_reports`
const COMMENTS_URL = `${API_URL}/v1/comments`
const USERS_URL = `${API_URL}/v1/users`
const JURISDICTION_URL = `${API_URL}/v1/jurisdictions`
const JURISDICTION_URL_V2 = `${API_URL}/v2/jurisdictions`
const APPLICATION_TYPE_URL = `${API_URL}/v1/application_types`
const PERMITY_APPS_URL = `${API_URL}/v1/permity_apps`
const EVENTS_URL = `${API_URL}/v1/events`
const FILE_RULESETS_URL = `${API_URL}/v1/file_rulesets`
const CHECLISTS_URL = `${API_URL}/v1/checklists`
const INTEGRATIONS_URL = `${API_URL}/v1/integrations`

export const saveReportPDF = async (
  organizationId: BCID,
  applicationId: BCID,
  dataUri: string,
): Promise<string> => {
  return axios.post(`${INTEGRATIONS_URL}/report-pdf`, { organizationId, applicationId, dataUri })
}

export const getFileRulesetList = async (): Promise<FileRuleset[]> => {
  return axios.get(FILE_RULESETS_URL).then((response) => response.data)
}

export const createFileRuleset = async (
  fileRuleset: FileRulesetCreate,
): Promise<FileRuleset | undefined> => {
  return axios.post(FILE_RULESETS_URL, fileRuleset).then((response) => response.data)
}

export const updateFileRuleset = async (
  id: BCID,
  fileRuleset: FileRulesetUpdate,
): Promise<FileRuleset | undefined> => {
  return axios.put(`${FILE_RULESETS_URL}/${id}`, fileRuleset).then((response) => response.data)
}

export const deleteFileRuleset = async (id: BCID): Promise<FileRuleset | undefined> => {
  return axios.delete(`${FILE_RULESETS_URL}/${id}`).then((response) => response.data)
}

export const getChecklistFromText = async (text: string): Promise<string> => {
  const {
    data: { jobId },
  } = await axios.post(`${CHECLISTS_URL}/from-text`, { text })

  // eslint-disable-next-line no-constant-condition
  while (true) {
    console.log('b')
    try {
      const response = await axios.get(`${CHECLISTS_URL}/jobs/${jobId}`)
      if (response.status === 200 && response.data.checklistText) {
        return response.data.checklistText
      }
    } catch {
      // eslint-disable-next-line no-empty
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 2000))
    }
  }
}

export const postEvent = async (event: Event): Promise<Event | undefined> => {
  return axios.post(EVENTS_URL, event).then((response) => response.data)
}

export const getSubscriptions = async (orgId?: BCID): Promise<Subscription[] | undefined> => {
  if (!orgId) return []
  return axios
    .get(`${ORGANIZATIONS_URL}/${orgId}/subscriptions`)
    .then((response) => response.data || [])
}

export const getExpenses = async (orgId?: BCID): Promise<Expense[] | undefined> => {
  if (!orgId) return []
  return axios.get(`${ORGANIZATIONS_URL}/${orgId}/expenses`).then((response) => response.data || [])
}

export const createApplication = async (
  app: ApplicationCreate,
): Promise<Application | undefined> => {
  return axios.post(APPLICATIONS_URL, app).then((response) => response.data)
}

export const updateApplication = async (
  appId: BCID,
  app: Partial<ApplicationCreate>,
  tottus = false,
): Promise<Application | undefined> => {
  return axios
    .put(`${tottus ? APPLICATIONS_URL : PERMITY_APPS_URL}/${appId}`, app)
    .then((response) => response.data)
}

export const submitRun = async (appId: BCID): Promise<ApplicationRun | undefined> => {
  return axios.post(`${PERMITY_APPS_URL}/${appId}/runs`).then((response) => response.data)
}

export const submitRecheck = async (runId: BCID): Promise<boolean | undefined> => {
  return axios.post(`${RUNS_URL}/${runId}/recheck`).then((response) => response.data)
}

export const getApplicationRequirements = async (
  appId?: BCID,
): Promise<AppRequirement[] | undefined> => {
  if (!appId) return
  return axios.get(`${PERMITY_APPS_URL}/${appId}/requirements`).then((response) => response.data)
}

export const upsertApplicationRequirements = async (
  appId: BCID,
  documentType: string,
  requirements: AppRequirementUpsert,
): Promise<AppRequirement | undefined> => {
  return axios
    .post(`${PERMITY_APPS_URL}/${appId}/requirements?document_type=${documentType}`, requirements)
    .then((response) => response.data)
}

export const createExternalApplication = async (
  app: ExternalAppCreate,
): Promise<Application | undefined> => {
  return axios
    .post(`${APPLICATIONS_URL}/external`, {
      ...app,
      permitType: app.permitType[0],
    })
    .then((response) => response.data)
}

export const createPermityApp = async (app: PermityAppCreate): Promise<Application | undefined> => {
  return axios.post(PERMITY_APPS_URL, app).then((response) => response.data)
}

export const createPermityAppDemo = async (
  app: PermityAppCreate,
): Promise<Application | undefined> => {
  return axios.post(`${PERMITY_APPS_URL}/demo`, app).then((response) => response.data)
}

export const updateApplicationStatus = async (
  appId: BCID,
  status: WooStatus,
): Promise<Application | undefined> => {
  return axios
    .put(`${APPLICATIONS_URL}/${appId}/status/${status}`)
    .then((response) => response.data)
}

export const updateRunWooStatus = async (
  runId: BCID,
  wooStatus: WooStatus,
): Promise<ApplicationRun | undefined> => {
  return axios.put(`${RUNS_URL}/${runId}`, { wooStatus }).then((response) => response.data)
}

export const getExportDetails = async (appId: BCID): Promise<ExportDetails | undefined> => {
  return axios.get(`${APPLICATIONS_URL}/${appId}/export`).then((response) => response.data)
}

// NOTE: should be removed
// see createNewAppVersion
export const addApplicationDocuments = async (
  appId: BCID,
  documentList: DocumentCreate[],
  documentType?: string,
): Promise<Application | undefined> => {
  return axios
    .put(`${APPLICATIONS_URL}/${appId}/documents`, {
      documentList,
      documentType,
    })
    .then((response) => response.data)
}

export const createNewAppVersion = async (
  appId: BCID,
  documentList: DocumentCreate[],
  copyPreviousDocuments = false,
): Promise<Application | undefined> => {
  return axios
    .put(
      `${APPLICATIONS_URL}/${appId}/documents?copy_previous_documents=${copyPreviousDocuments}`,
      {
        documentList,
      },
    )
    .then((response) => response.data)
}

export const addPermityDocuments = async (
  appId: BCID,
  documentList: DocumentCreate[],
  documentType?: string,
): Promise<Application | undefined> => {
  return axios
    .put(`${PERMITY_APPS_URL}/${appId}/documents`, {
      documentList,
      documentType,
    })
    .then((response) => response.data)
}

export const getApplicationsV2 = async (
  orgId?: BCID,
): Promise<ApplicationListItem[] | undefined> => {
  return orgId
    ? axios
        .get(APPLICATIONS_URL_V2, { params: { org_id: orgId } })
        .then((response) => response.data || [])
    : []
}

export const getExternalApplications = async (
  orgId?: BCID,
): Promise<ApplicationListItem[] | undefined> => {
  return orgId
    ? axios
        .get(PERMITY_APPS_URL, { params: { org_id: orgId } })
        .then((response) => response.data || [])
    : []
}

export const getAllApplications = async (): Promise<ApplicationListItem[] | undefined> => {
  return axios.get(APPLICATIONS_URL).then((response) => response.data || [])
}

export const getAllInternalAppsV2 = async (): Promise<InternalApp[] | undefined> => {
  return axios.get(`${APPLICATIONS_URL_V2}/internal`).then((response) => response.data || [])
}

export const getAllPermityApps = async (): Promise<InternalApp[] | undefined> => {
  return axios.get(PERMITY_APPS_URL).then((response) => response.data || [])
}

export const getApplication = async (appId?: BCID): Promise<ApplicationExtended | undefined> => {
  return axios.get(`${APPLICATIONS_URL}/${appId}`).then((response) => response.data || [])
}

export const deleteApplication = async (appId?: BCID): Promise<ApplicationExtended | undefined> => {
  return axios.delete(`${APPLICATIONS_URL}/${appId}`).then((response) => response.data || [])
}

export const getApplicationRuns = async (appId?: BCID): Promise<ApplicationRun[] | undefined> => {
  return axios.get(`${APPLICATIONS_URL}/${appId}/runs`).then((response) => response.data || [])
}

export const getApplicationRunsV2 = async (
  appId?: BCID,
): Promise<ApplicationRunWithTrafficLights[] | undefined> => {
  return axios.get(`${APPLICATIONS_URL_V2}/${appId}/runs`).then((response) => response.data || [])
}

export const getRunLatestReport = async (runId?: BCID): Promise<AppReport | undefined> => {
  return axios.get(`${RUNS_URL}/${runId}/report/latest`).then((response) => response.data)
}

export const getRunPredictions = async (runId?: BCID): Promise<AppRunPredictions | undefined> => {
  return axios.get(`${RUNS_URL}/${runId}/predictions`).then((response) => response.data)
}

export const getRunDocuments = async (runId?: BCID): Promise<Document[] | undefined> => {
  if (!runId) return
  return axios.get(`${RUNS_URL}/${runId}/documents`).then((response) => response.data)
}

export const createOrganization = async (
  org: OrganizationCreate,
): Promise<Organization | undefined> => {
  return axios.post(ORGANIZATIONS_URL, org).then((response) => response.data)
}

export const updateOrganization = async (
  orgId: BCID,
  org: OrganizationUpdate,
): Promise<Organization | undefined> => {
  return axios.put(`${ORGANIZATIONS_URL}/${orgId}`, org).then((response) => response.data)
}

export const deleteOrganization = async (orgId: BCID): Promise<Organization | undefined> => {
  return axios.delete(`${ORGANIZATIONS_URL}/${orgId}`).then((response) => response.data)
}

export const getOrganizations = async (): Promise<Organization[] | undefined> => {
  return axios.get(ORGANIZATIONS_URL).then((response) => response.data || [])
}

export const getOrganization = async (orgId?: BCID): Promise<Organization | undefined> => {
  if (!orgId) return
  return axios.get(`${ORGANIZATIONS_URL}/${orgId}`).then((response) => response.data || [])
}

export const createJurisdiction = async (
  jurisdictionId: BCID,
  jurisdiction: JurisdictionCreate,
): Promise<Jurisdiction | undefined> => {
  return axios
    .post(`${ORGANIZATIONS_URL}/${jurisdictionId}/jurisdictions`, jurisdiction)
    .then((response) => response.data)
}

export const updateJurisdiction = async (
  jurisdictionId: BCID,
  jurisdiction: JurisdictionUpdate,
): Promise<Jurisdiction | undefined> => {
  return axios
    .put(`${JURISDICTION_URL}/${jurisdictionId}`, jurisdiction)
    .then((response) => response.data)
}

export const getJurisdiction = async (jurisdictionId?: BCID): Promise<Jurisdiction | undefined> => {
  if (!jurisdictionId) return
  return axios.get(`${JURISDICTION_URL}/${jurisdictionId}`).then((response) => response.data || [])
}

export const getAllJurisdictions = async (): Promise<Jurisdiction[] | undefined> => {
  return axios.get(JURISDICTION_URL).then((response) => response.data || [])
}

export const deleteJurisdiction = async (
  jurisdictionId?: BCID,
): Promise<ApplicationExtended | undefined> => {
  return axios
    .delete(`${JURISDICTION_URL}/${jurisdictionId}`)
    .then((response) => response.data || [])
}

export const getJurisdictions = async (orgId?: BCID): Promise<Jurisdiction[] | undefined> => {
  if (!orgId) return []
  return axios.get(`${ORGANIZATIONS_URL}/${orgId}/jurisdictions`).then((response) => response.data)
}

export const getTemplates = async (
  jurisdictionId?: BCID,
): Promise<TemplateWithPages[] | undefined> => {
  if (!jurisdictionId) return []
  return axios
    .get(`${JURISDICTION_URL}/${jurisdictionId}/templates`)
    .then((response) => response.data)
}

export const createApplicationType = async (
  jurisdictionId: BCID,
  applicationType: ApplicationTypeCreate,
): Promise<ApplicationType | undefined> => {
  return axios
    .post(`${JURISDICTION_URL}/${jurisdictionId}/application-types`, applicationType)
    .then((response) => response.data)
}

export const updateApplicationType = async (
  jurisdictionId: BCID,
  applicationTypeId: BCID,
  applicationType: ApplicationTypeUpdate,
): Promise<ApplicationType | undefined> => {
  return axios
    .put(
      `${JURISDICTION_URL}/${jurisdictionId}/application-types/${applicationTypeId}`,
      applicationType,
    )
    .then((response) => response.data)
}

export const deleteApplicationType = async (
  jurisdictionId: BCID,
  applicationTypeId: BCID,
): Promise<ApplicationType | undefined> => {
  return axios
    .delete(`${JURISDICTION_URL}/${jurisdictionId}/application-types/${applicationTypeId}`)
    .then((response) => response.data)
}

export const getGlobalApplicationType = async (): Promise<ApplicationType[] | undefined> => {
  return axios.get(APPLICATION_TYPE_URL).then((response) => response.data)
}

export const getAppType = async (appTypeId?: BCID): Promise<ApplicationType | undefined> => {
  if (!appTypeId) return
  return axios.get(`${APPLICATION_TYPE_URL}/${appTypeId}`).then((response) => response.data)
}

export const getApplicationTypes = async (
  jurisdictionId?: BCID,
): Promise<ApplicationType[] | undefined> => {
  if (!jurisdictionId) return []
  return axios
    .get(`${JURISDICTION_URL}/${jurisdictionId}/application-types`)
    .then((response) => response.data)
}

export const createTemplateV2 = async (
  jurisdictionId: BCID,
  template: TemplateCreate,
): Promise<Template | undefined> => {
  return axios
    .post(`${JURISDICTION_URL_V2}/${jurisdictionId}/templates`, template)
    .then((response) => response.data)
}

export const deleteTemplate = async (
  jurisdictionId: BCID,
  templateId: BCID,
): Promise<Template | undefined> => {
  return axios
    .delete(`${JURISDICTION_URL}/${jurisdictionId}/templates/${templateId}`)
    .then((response) => response.data)
}

export const getDownloadLink = async (key: string): Promise<string | undefined> => {
  return axios.get(`${FILES_URL}/download/${key}`).then((response) => response.data)
}

export const createReportVersion = async (
  report: AppReport,
  markForRerun = false,
  lastSyncId?: BCID,
): Promise<AppReport> => {
  return axios
    .post(
      `${APP_REPORT_URL}?mark_for_rerun=${markForRerun}${
        lastSyncId ? `&last_sync_id=${lastSyncId}` : ''
      }`,
      report,
    )
    .then((response) => response.data)
}

export const updateCheck = async (
  runId: BCID,
  id: BCID,
  body: {
    status?: CheckResponseStatus
    message?: string
  },
): Promise<boolean> => {
  return axios.put(`${RUNS_URL}/${runId}/checks/${id}`, body).then((response) => response.data)
}

export const deleteCheck = async (runId: BCID, id: BCID): Promise<boolean> => {
  return axios.delete(`${RUNS_URL}/${runId}/checks/${id}`).then((response) => response.data)
}

export const updateDocumentTypeMap = async (
  runId: BCID,
  documentTypeMap: Record<string, DocumentTypeDetail[]>,
  markForRerun = false,
): Promise<boolean> => {
  return axios
    .put(`${RUNS_URL}/${runId}/document_type_map?mark_for_rerun=${markForRerun}`, documentTypeMap)
    .then((response) => response.data)
}

export const createComment = async (
  message: string,
  entityid: string,
): Promise<Comment | undefined> => {
  return axios
    .post(COMMENTS_URL, { message, applicationId: entityid })
    .then((response) => response.data)
}

export const updateOrganizationUser = async (
  orgId: BCID,
  orgUserId: BCID,
  orgUser: Partial<OrganizationUser>,
): Promise<Organization | undefined> => {
  return axios
    .put(`${ORGANIZATIONS_URL}/${orgId}/users/${orgUserId}`, orgUser)
    .then((response) => response.data)
}

export const addOrganizationUser = async (
  orgId: BCID,
  orgUser: OrganizationUserCreate,
): Promise<Organization | undefined> => {
  return axios
    .post(`${ORGANIZATIONS_URL}/${orgId}/users`, orgUser)
    .then((response) => response.data)
}

export const deleteOrganizationUser = async (
  orgId: BCID,
  orgUserId: BCID,
): Promise<Organization | undefined> => {
  return axios
    .delete(`${ORGANIZATIONS_URL}/${orgId}/users/${orgUserId}`)
    .then((response) => response.data)
}

export const getUsers = async (): Promise<User[] | undefined> => {
  return axios.get(USERS_URL).then((response) => response.data)
}

export const createAppFeedback = async (
  appId: BCID,
  feedback: AppFeedbackCreate,
): Promise<AppFeedback | undefined> => {
  return axios
    .post(`${APPLICATIONS_URL}/${appId}/feedback`, feedback)
    .then((response) => response.data)
}

export const getAppFeedback = async (
  appId: BCID,
  type?: string,
): Promise<AppFeedback[] | undefined> => {
  return axios
    .get(`${APPLICATIONS_URL}/${appId}/feedback/me${type ? `?type=${type}` : ''}`)
    .then((response) => response.data)
}

export const getVariables = async (orgId?: BCID): Promise<OrganizationVariables | undefined> => {
  if (!orgId) return
  return axios.get(`${ORGANIZATIONS_URL}/${orgId}/variables`).then((response) => response.data)
}

export const updateVariables = async (
  orgId: BCID,
  variables: OrganizationVariablesUpdate,
): Promise<OrganizationVariables | undefined> => {
  return axios
    .put(`${ORGANIZATIONS_URL}/${orgId}/variables`, variables)
    .then((response) => response.data)
}

// Get comments for run

export const getRunComments = async (
  runId: string,
  documentType?: string,
): Promise<RunComment[] | undefined> => {
  return axios
    .get(`${RUNS_URL}/${runId}/comments`, {
      params: { document_type: documentType },
    })
    .then((response) => response.data)
}

export const createRunComment = async (
  runId: string,
  comment: RunDocumentCommentCreate,
): Promise<RunComment | undefined> => {
  return axios
    .post(`${RUNS_URL}/${runId}/comments`, {
      ...comment,
      parentId: comment.parentId || null,
    })
    .then((response) => response.data)
}

export const updateRunComment = async (
  runId: string,
  comment: RunDocumentCommentCreate,
): Promise<RunComment | undefined> => {
  return axios
    .put(`${RUNS_URL}/${runId}/comments/${comment?.commentId}`, comment)
    .then((response) => response.data)
}

export const deleteRunComment = async (
  runId: string,
  commentId: string,
): Promise<RunComment | undefined> => {
  return axios
    .delete(`${RUNS_URL}/${runId}/comments/${commentId}`)
    .then((response) => response.data)
}
