import { useEffect, FC, useCallback } from 'react'

interface Props {
  onClose: () => void
  onCancel?: () => void
  onSubmit?: () => void
  submitText?: string
  cancelText?: string
  icon?: string
  bodyText?: string | JSX.Element
}

export const ConfirmationModal: FC<Props> = ({
  onClose,
  onCancel,
  onSubmit,
  submitText = 'Okay',
  cancelText = 'Cancel',
  icon,
  bodyText,
}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const handleCancel = useCallback(() => {
    onCancel?.()
    onClose()
  }, [onCancel, onClose])

  const handleSubmit = useCallback(() => {
    onSubmit?.()
    onClose()
  }, [onSubmit, onClose])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7 d-flex flex-column align-items-center gap-10'>
              {icon && <i className={icon} style={{ fontSize: '64px' }} />}
              <div>{bodyText}</div>
              <div className='text-center'>
                <button
                  type='reset'
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                  onClick={handleCancel}
                >
                  {cancelText}
                </button>

                <button type='submit' className='btn btn-danger' onClick={handleSubmit}>
                  <span className='indicator-label'>{submitText}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}
