import clsx from 'clsx'
import { FC } from 'react'

import { toAbsoluteUrl } from 'src/_metronic/helpers'

interface Props {
  avatar?: string | null
  className?: string
}
export const ProjectAvatar: FC<Props> = ({ avatar, className = '' }) => {
  return (
    <div
      className={clsx(
        'w-100 h-100 d-flex align-items-center justify-content-center position-relative',
        !avatar && 'border border-3 p-5',
        className,
      )}
    >
      {avatar ? (
        <img
          className='rounded'
          src={toAbsoluteUrl(avatar)}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          crossOrigin='anonymous'
        />
      ) : (
        <img
          className='rounded'
          src={toAbsoluteUrl('/media/projects/default.png')}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            filter:
              'invert(70%) sepia(100%) saturate(0%) hue-rotate(197deg) brightness(95%) contrast(97%)',
          }}
        />
      )}
    </div>
  )
}
