/* eslint-disable react/jsx-no-target-blank */
import { Box, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

import { useAuth } from '../core/Auth'
import { AuthModel } from '../core/_models'
import { getUserByToken } from '../core/_requests'
import { authenticate, resetPassword } from '../core/cognito'
import { passwordValidator, confirmPasswordValidator } from '../core/validations'
import { AcceptTerms } from './components/AcceptTerms'
import { BCButton } from './components/Button'
import { ErrorContainer } from './components/ErrorContainer'
import { PasswordBar } from './components/PasswordBar'
import { PasswordField } from './components/PasswordField'

const initialValues = {
  password: '',
  changepassword: '',
  acceptTerms: false,
}

interface Props {
  setupNewPassword?: (pwd: string) => Promise<AuthModel>
}

export const Reset: FC<Props> = ({ setupNewPassword }) => {
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const { saveAuth, setCurrentUser } = useAuth()

  const code = searchParams.get('code')
  const username = searchParams.get('username')

  const registrationSchema = Yup.object().shape({
    password: passwordValidator,
    changepassword: confirmPasswordValidator,
    acceptTerms: setupNewPassword
      ? Yup.bool().oneOf([true], 'You must accept the Terms of Service and Privacy Policy')
      : Yup.bool(),
  })

  const { enqueueSnackbar } = useSnackbar()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setSubmitting(true)
      try {
        let authData = null

        if (setupNewPassword) {
          // Change from temprorary
          authData = await setupNewPassword(values.password)

          enqueueSnackbar('Password set successfully', {
            variant: 'success',
          })
        } else {
          // Forgot or reset
          if (!username || !code) return

          await resetPassword(username, code, values.password)

          enqueueSnackbar('Password reset successfully', {
            variant: 'success',
          })

          const { auth } = await authenticate(username, values.password)
          authData = auth
        }

        saveAuth(authData)

        const { data: user } = await getUserByToken()
        setCurrentUser(user)
      } catch (err) {
        if (err instanceof Error) {
          return setStatus(err.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <Box
      component='form'
      noValidate
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        p: '20px',
      }}
    >
      {(formik.status || (formik.touched.acceptTerms && formik.errors.acceptTerms)) && (
        <ErrorContainer>{formik.status || formik.errors.acceptTerms}</ErrorContainer>
      )}

      <Typography variant='h4' sx={{ textAlign: 'center' }}>
        {setupNewPassword ? 'Set up new password' : 'Reset Password'}
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gap: '5px',
          }}
        >
          <PasswordField
            autoFocus
            type='password'
            label='Password'
            variant='outlined'
            autoComplete='off'
            fullWidth
            {...formik.getFieldProps('password')}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={(formik.touched.password && formik.errors.password) || ' '}
          />
          <PasswordBar password={formik.values.password} />
        </Box>
        <Typography variant='body1' color='#a1a5b7' fontWeight={500}>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </Typography>
      </Box>

      <PasswordField
        type='password'
        label='Confirm Password'
        variant='outlined'
        fullWidth
        {...formik.getFieldProps('changepassword')}
        error={formik.touched.changepassword && Boolean(formik.errors.changepassword)}
        helperText={(formik.touched.changepassword && formik.errors.changepassword) || ' '}
      />

      {setupNewPassword && (
        <AcceptTerms {...formik.getFieldProps('acceptTerms')} checked={formik.values.acceptTerms} />
      )}

      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <BCButton
          fullWidth
          inProgress={loading}
          disabled={loading || !formik.isValid || !formik.dirty}
          type='submit'
          sx={{ flexGrow: 1 }}
        >
          Change Password
        </BCButton>
      </Box>
    </Box>
  )
}
