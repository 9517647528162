import { TextField, Typography, Link as MuiLink, Box } from '@mui/material'
import axios from 'axios'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

// import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { AuthModel } from '../core/_models'
import { getUserByToken } from '../core/_requests'
import { authenticate } from '../core/cognito'
import { MFA } from './MFA'
import { Reset } from './Reset'
import { BCButton } from './components/Button'
import { ErrorContainer } from './components/ErrorContainer'
import { PasswordField } from './components/PasswordField'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [params] = useSearchParams()
  const returnUrl = params.get('returnUrl')
  const email = params.get('email')
  const userId = params.get('user_id')
  const code = params.get('code')
  const navigate = useNavigate()
  const [authState, setAuthState] = useState<{
    setupNewPassword?: (password: string) => Promise<AuthModel>
    mfaConfirm?: (code: string) => Promise<AuthModel>
  } | null>(null)

  useEffect(() => {
    if (userId && code) {
      handleAuth(userId, code)
    }
  }, [userId, code])

  const handleAuth = useCallback(
    async (username: string, password: string, errorHandler = console.log) => {
      setLoading(true)
      try {
        const { auth, setupNewPassword, mfaConfirm } = await authenticate(username, password)

        if (mfaConfirm) {
          return setAuthState({ mfaConfirm })
        }

        if (setupNewPassword) {
          return setAuthState({ setupNewPassword })
        }
        saveAuth(auth)

        const { data: user } = await getUserByToken()
        setCurrentUser(user)

        if (returnUrl) {
          return navigate(returnUrl)
        }
      } catch (error) {
        saveAuth(undefined)
        if (axios.isAxiosError(error)) {
          errorHandler(error.response?.data.detail)
        } else {
          errorHandler('The login details are incorrect')
        }
      } finally {
        setLoading(false)
      }
    },
    [],
  )

  const formik = useFormik({
    initialValues: {
      email: email || '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus }) => {
      await handleAuth(values.email.trim(), values.password.trim(), setStatus)
    },
  })

  if (authState?.setupNewPassword) {
    return <Reset setupNewPassword={authState.setupNewPassword} />
  }

  if (authState?.mfaConfirm) {
    return <MFA mfaConfirm={authState.mfaConfirm} />
  }
  return (
    <Box
      component='form'
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        p: '20px',
      }}
    >
      {formik.status && <ErrorContainer>{formik.status}</ErrorContainer>}
      <Box sx={{ display: 'flex', justifyContent: 'center', p: '20px' }}>
        <Typography variant='h4'>Welcome</Typography>
      </Box>

      <TextField
        autoFocus
        id='field-login'
        label='Email'
        variant='outlined'
        InputLabelProps={{
          // NOTE: 1pass and other password managers autofill broke the animation
          shrink: true,
        }}
        fullWidth
        {...formik.getFieldProps('email')}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={(formik.touched.email && formik.errors.email) || ' '}
      />

      <Box>
        <PasswordField
          id='field-password'
          variant='outlined'
          fullWidth
          type='password'
          label='Password'
          InputLabelProps={{
            // NOTE: 1pass and other password managers autofill broke the animation
            shrink: true,
          }}
          {...formik.getFieldProps('password')}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={(formik.touched.password && formik.errors.password) || ' '}
        />
        <Box
          sx={{
            // display: 'flex',
            // justifyContent: 'flex-end',
            position: 'relative',
          }}
        >
          <MuiLink
            component={Link}
            tabIndex={-1}
            to='/auth/forgot-password'
            sx={{
              position: 'absolute',
              right: 0,
              top: -12,
            }}
          >
            Forgot Password?
          </MuiLink>
        </Box>
      </Box>

      <BCButton
        id='submit-login'
        sx={{
          mt: '10px',
        }}
        type='submit'
        disabled={formik.isSubmitting || !formik.isValid}
        inProgress={loading}
      >
        Continue
      </BCButton>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <Typography variant='body1' fontWeight={500} color='#a1a5b7'>
          Not a Member yet?
        </Typography>
        <MuiLink tabIndex={-1} component={Link} to='/auth/registration'>
          Sign Up
        </MuiLink>
      </Box>
    </Box>
  )
}
