import clsx from 'clsx'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { MenuComponent } from '../assets/ts/components'
import {
  ActivityDrawer,
  DrawerMessenger,
  InviteUsers,
  ThemeModeProvider,
  UpgradePlan,
} from '../partials'
import { Content } from './components/Content'
import { Footer } from './components/Footer'
import { ScrollTop } from './components/ScrollTop'
import { AsideDefault } from './components/aside/AsideDefault'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { PageDataProvider } from './core'
import { useLayout } from './core'

const MasterLayout = () => {
  const location = useLocation()
  const { config } = useLayout()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  const style = config.content.layout === 'full-height' ? { paddingTop: '65px' } : {}

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root'>
          {/* begin::Page */}
          <div className='page d-flex flex-column flex-column-fluid vh-100'>
            <HeaderWrapper />

            {/* begin::Wrapper */}
            <div
              className={clsx(
                'wrapper d-flex flex-column flex-row-fluid mh-0 flex-shrink-1',
                config.content.layout === 'full-height' && 'pt-50px',
              )}
              style={{ ...style, minHeight: 0 }}
              id='kt_wrapper'
            >
              <AsideDefault />

              {config.content.layout !== 'full-height' && <Toolbar />}

              {/* begin::Content */}
              <div
                id='kt_content'
                className='content d-flex flex-column flex-column-fluid flex-grow-1 flex-shrink-1 p-0'
                style={{ minHeight: 0 }}
              >
                <Content>
                  <Outlet />
                </Content>
              </div>
              {/* end::Content */}
              <Footer />
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Page */}
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }
