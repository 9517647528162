import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useState, useCallback } from 'react'
import { useSearchParams, useNavigate, Link } from 'react-router-dom'

import { confirmSignUp, resendVerificationCode } from '../core/cognito'
import { BCButton } from './components/Button'
import { ErrorContainer } from './components/ErrorContainer'

export const Confirm: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { enqueueSnackbar } = useSnackbar()

  const code = searchParams.get('code')
  const username = searchParams.get('username')
  const email = searchParams.get('email')

  const [errMsg, setErrMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('Email has been verified')

  useEffect(() => {
    if (!code || !username) return
    confirmSignUp(username, code)
      .then(() => {
        navigate(`/auth?email=${email}`)
        enqueueSnackbar('Your account has been confirmed. Please login.', {
          variant: 'success',
        })
      })
      .catch((err) => {
        setErrMsg(err.message)
      })
  }, [code, username])

  const resendVerification = useCallback(async () => {
    if (!username) return

    await resendVerificationCode(username)
    setSuccessMsg('Verification code has been resent')
    setErrMsg('')
  }, [username])

  return errMsg ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <ErrorContainer>{errMsg}</ErrorContainer>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <BCButton fullWidth onClick={resendVerification}>
          Resend verification link
        </BCButton>

        <Link to='/auth/login' tabIndex={-1}>
          <BCButton variant='text' type='button'>
            Cancel
          </BCButton>
        </Link>
      </Box>
    </Box>
  ) : (
    <>{successMsg}</>
  )
}
