import { Button, Box, Typography, ButtonProps, CircularProgress } from '@mui/material'
import { FC } from 'react'

interface Props extends ButtonProps {
  inProgress?: boolean
  inProgressLabel?: string
  label?: string
}

export const BCButton: FC<Props> = ({
  inProgress = false,
  label = 'Complete',
  inProgressLabel = 'Please wait...',
  sx,
  children,
  ...rest
}) => (
  <Button
    variant='contained'
    sx={{
      p: '15px',
      textTransform: 'none',
      boxShadow: 'none',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 1,
      ...sx,
    }}
    {...rest}
  >
    {!inProgress && (children || <Typography>{label}</Typography>)}
    {inProgress && (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', lineHeight: 1 }}>
        {inProgressLabel}
        <CircularProgress color='secondary' size={14} />
      </Box>
    )}
  </Button>
)
