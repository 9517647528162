/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import { FC, useMemo } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'

import { App } from '../App'
import { UserRole } from '../modules/admin/core/_models'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { PrivateRoutes } from './PrivateRoutes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()

  const navigateUrl = useMemo(
    () => (currentUser?.role === UserRole.BC_REVIEWER ? '/admin/apps' : '/applications'),
    [currentUser?.role],
  )

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to={navigateUrl} replace />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route
                path='*'
                element={<Navigate to={`/auth?returnUrl=${window.location.pathname}`} />}
              />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
