import { Box, Typography } from '@mui/material'
import { FC } from 'react'

import { toAbsoluteUrl } from 'src/_metronic/helpers'

import './Landing.css'

export const Landing: FC = () => {
  return (
    <Box className='waveWrapper waveAnimation'>
      <Box className='waveWrapperInner bgTop'>
        <Box
          className='wave waveTop'
          sx={{
            backgroundImage: `url('${toAbsoluteUrl('/media/landing/wave-top.png')}')`,
          }}
        ></Box>
      </Box>
      <Box className='waveWrapperInner bgMiddle'>
        <Box
          className='wave waveMiddle'
          sx={{
            backgroundImage: `url('${toAbsoluteUrl('/media/landing/wave-mid.png')}')`,
          }}
        ></Box>
      </Box>
      <Box className='waveWrapperInner bgBottom'>
        <Box
          className='wave waveBottom'
          sx={{
            backgroundImage: `url('${toAbsoluteUrl('/media/landing/wave-bot.png')}')`,
          }}
        ></Box>
      </Box>
      <Typography
        color='white'
        variant='h1'
        fontSize={50}
        fontWeight={600}
        sx={{
          position: 'absolute',
          top: '20%',
          textAlign: 'center',
          zIndex: 99,
          width: '100%',
          px: '50px',
        }}
      >
        Supercharge your permit reviews
      </Typography>
    </Box>
  )
}
