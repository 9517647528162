import { createTheme } from '@mui/material/styles'

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    permityCard: true
    permityAppBar: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    documentRequested: Palette['primary']
    permityComments: Palette['primary']
    permityReview: Palette['primary']
    requestDenied: Palette['primary']
    readyToSubmit: Palette['primary']
  }

  interface PaletteOptions {
    documentRequested?: PaletteOptions['primary']
    permityComments?: PaletteOptions['primary']
    permityReview?: PaletteOptions['primary']
    requestDenied?: PaletteOptions['primary']
    readyToSubmit?: PaletteOptions['primary']
  }
}

export const media = {
  TABLET: '@media (min-width: 840px)',
  DESKTOP: '@media (min-width: 1440px)',
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3E97FF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#A1A5B7',
      contrastText: '#fff',
    },
    info: {
      main: '#5E6278',
      contrastText: '#fff',
    },
    error: {
      main: '#F1416C',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Inter, Helvetica, "sans-serif"',
    body1: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 1,
    },
    body2: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: 1,
    },
    h1: {
      fontSize: '32px',
      lineHeight: 1,
      fontWeight: 600,
    },
    h2: {
      fontSize: '28px',
      lineHeight: 1,
      fontWeight: 600,
    },
    h3: {
      fontSize: '24px',
      lineHeight: 1,
      fontWeight: 600,
    },
    h4: {
      fontSize: '20px',
      lineHeight: 1,
      fontWeight: 600,
    },
    h5: {
      fontSize: '18px',
      lineHeight: 1,
      fontWeight: 600,
    },
    h6: {
      fontSize: '16px',
      lineHeight: 1,
      fontWeight: 600,
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#3E97FF',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: 1,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '0.9375rem',
          fontWeight: 600,
          textTransform: 'none',
          minWidth: 'auto',
          p: '0.75rem 0.875rem',
          borderRadius: '0.375rem',
          '&.Mui-selected': {
            backgroundColor: 'var(--gray-gray-200, #F1F1F2)',
            color: '#000',
            boxShadow: 'none',
          },
        },
      },
    },
  },
})

let permityTheme = createTheme()

permityTheme = createTheme(permityTheme, {
  palette: {
    primary: permityTheme.palette.augmentColor({
      color: {
        main: '#1D7873',
      },
    }),
    warning: permityTheme.palette.augmentColor({
      color: {
        main: '#F6C000',
      },
    }),
    success: permityTheme.palette.augmentColor({
      color: {
        main: '#47BE7D',
      },
    }),
    text: {
      primary: theme.palette.grey[800],
      secondary: theme.palette.grey[600],
    },
    background: {
      default: theme.palette.grey[200],
    },
    documentRequested: permityTheme.palette.augmentColor({
      color: {
        main: '#2884EF',
      },
    }),
    permityComments: permityTheme.palette.augmentColor({
      color: {
        main: '#5014D0',
      },
    }),
    permityReview: permityTheme.palette.augmentColor({
      color: {
        main: '#F6C000',
      },
    }),
    requestDenied: permityTheme.palette.augmentColor({
      color: {
        main: '#D9214E',
      },
    }),
    readyToSubmit: permityTheme.palette.augmentColor({
      color: {
        main: '#47BE7D',
      },
    }),
  },
})

permityTheme = createTheme(permityTheme, {
  typography: {
    fontFamily: 'Roboto, Helvetica, "sans-serif"',
    h2: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 1.6,
    },
    h5: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: 1.6,
    },
    h6: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.6,
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.5,
      color: theme.palette.grey[500],
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.5,
      color: theme.palette.grey[500],
    },
    caption: {
      fontSize: '12px',
    },
  },
  components: {
    MuiAppBar: {
      variants: [
        {
          props: { variant: 'permityAppBar' },
          style: {
            backgroundColor: '#fff',
            boxShadow: 'none',
            borderBottom: '1px solid #E5E5E5',
          },
        },
      ],
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 90,
          minHeight: 90,
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'permityCard' },
          style: {
            borderRadius: '4px',
            padding: '20px',
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: permityTheme.palette.primary.main,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: permityTheme.palette.primary.main,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          padding: '4px',
          fontSize: '12px',
          '& .MuiChip-label': {
            whiteSpace: 'break-spaces',
            padding: '3px 6px',
          },
          height: 'auto',
        },
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'default' },
          style: {
            borderColor: theme.palette.grey[300],
          },
        },
        {
          props: { variant: 'filled', color: 'default' },
          style: {
            backgroundColor: theme.palette.grey[500],
            color: '#fff',
            fontWeight: 500,
          },
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '20px',
          fontSize: 20,
          fontFamily: 'Roboto',
          fontWeight: '500',
          lineHeight: 1.6,
          letterSpacing: 0.03,
          wordWrap: 'break-word',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-tag': {
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.grey[800],
            borderRadius: '100px',
            gap: '6px',
          },
        },
        option: {
          fontSize: '16px',
          fontWeight: 400,
          color: theme.palette.grey[700],
          display: 'flex',
        },
        listbox: {
          "& .MuiAutocomplete-option[aria-selected='true']": {
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '20px',
          borderTop: '1px solid',
          borderColor: theme.palette.grey[300],
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '20px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
          color: theme.palette.grey[500],
          '&.Mui-selected': {
            color: permityTheme.palette.primary.main,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            fontSize: '20px',
            lineHeight: 1.6,
            fontWeight: 500,
            letterSpacing: '0.03px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            fontSize: '14px',
            padding: '8px 16px',
          },
        },
        {
          props: { size: 'small' },
          style: {
            fontSize: '14px',
            padding: '6px 16px',
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[300],
        },
      },
    },
  },
})

export { permityTheme }
