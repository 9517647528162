import { debounce, DebouncedFunc } from 'lodash'
import { useMemo } from 'react'

// eslint-disable-next-line
export function useDebounce<T extends (...args: any[]) => unknown>(
  func: T,
  delay: number,
  deps: unknown[],
): DebouncedFunc<T> {
  return useMemo(() => debounce(func, delay), deps)
}
