/* eslint-disable react/jsx-no-target-blank */
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useIntl } from 'react-intl'

import { UserRole } from 'src/app/modules/admin/core/_models'
import { useAuth } from 'src/app/modules/auth'

import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItemWithSubMain } from './AsideMenuItemWithSubMain'

export function AsideMenuMain() {
  const intl = useIntl()
  const { currentUser } = useAuth()
  const flags = useFlags()
  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        title='Home'
        fontIcon='bi-house fs-2'
        bsTitle={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        className='py-2'
      /> */}
      {!flags.applicationsProcessing && (
        <AsideMenuItem
          to='/projects'
          title='Projects'
          fontIcon='bi-folder fs-2'
          bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
          className='py-2'
        />
      )}

      {flags.applicationsProcessing && currentUser?.role !== UserRole.APPLICANT && (
        <AsideMenuItem
          to={currentUser?.role === UserRole.BC_REVIEWER ? '/admin/apps' : '/applications'}
          title='Applications'
          fontIcon='bi-folder fs-2'
          // bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
          className='py-2'
        />
      )}

      {flags.applicationsProcessing && currentUser?.role === UserRole.APPLICANT && (
        <AsideMenuItem
          to='/applications'
          title='Projects'
          fontIcon='bi-house-door fs-2'
          // bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
          className='py-2'
        />
      )}

      {flags.applicationsProcessing && currentUser?.role === UserRole.APPLICANT && (
        <AsideMenuItem
          to='/activity'
          title='Activity'
          fontIcon='bi-activity fs-2'
          // bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
          className='py-2'
        />
      )}

      {flags.applicationsProcessing && currentUser?.role === UserRole.APPLICANT && (
        <AsideMenuItem
          to='/actions'
          title='Actions'
          fontIcon='bi-clipboard-check fs-2'
          // bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
          className='py-2'
        />
      )}

      {flags.applicationsProcessing && currentUser?.role === UserRole.APPLICANT && (
        <AsideMenuItem
          to='/team'
          title='Team'
          fontIcon='bi-people fs-2'
          // bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
          className='py-2'
        />
      )}

      {flags.applicationsProcessing && currentUser?.role === UserRole.APPLICANT && (
        <AsideMenuItem
          to='/archive'
          title='Archive'
          fontIcon='bi-clock fs-2'
          // bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
          className='py-2'
        />
      )}

      {flags.applicationsProcessing && currentUser?.isAdmin && (
        <AsideMenuItem to='/admin/apps' title='AI' fontIcon='bi-robot fs-2' />
      )}

      {flags.applicationsProcessing && currentUser?.isAdmin && (
        <AsideMenuItem
          to='/apps/user-management/users'
          title='Users'
          bsTitle='Users'
          fontIcon='bi-people fs-2'
          // bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
          className='py-2'
        />
      )}

      {flags.applicationsProcessing && currentUser?.isAdmin && (
        <AsideMenuItem
          to='/file_rulesets'
          title='Rulesets'
          bsTitle='Rulesets'
          fontIcon='bi bi-files fs-2'
          // bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
          className='py-2'
        />
      )}

      {flags.applicationsProcessing && currentUser?.isAdmin && (
        <AsideMenuItem
          to='/organizations'
          title='Organization'
          bsTitle='Organization'
          fontIcon='bi-diagram-3 fs-2'
          // bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
          className='py-2'
        />
      )}
    </>
  )
}
