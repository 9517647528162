import { Divider } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { getOrganizations } from 'src/app/modules/applications/core/_requests'
import { ProjectAvatar } from 'src/app/modules/projects/components/ProjectAvatar'

import { useAuth } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()

  const flags = useFlags()
  const { data: organizationList } = useQuery('organizations', getOrganizations)

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            {/*<div className='fw-bolder d-flex align-items-center fs-5'>*/}
            {/*  {currentUser?.first_name} {currentUser?.first_name}*/}
            {/*  <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>*/}
            {/*</div>*/}
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Profile Settings
        </Link>
      </div>

      <Divider />

      {!currentUser?.isAdmin &&
        organizationList?.map((organization) => (
          <div key={organization.id} className='menu-item px-5'>
            <Link
              to={`organizations/${organization.id}`}
              className='menu-link px-5 d-flex align-items-center'
            >
              {organization?.avatar && (
                <ProjectAvatar avatar={organization?.avatar} className='w-20px h-20px me-1' />
              )}
              {organization.name} Settings
            </Link>
          </div>
        ))}

      <Divider />

      <div className='menu-item px-5'>
        <a
          onClick={() => {
            logout()
            window.location.reload()
          }}
          className='menu-link px-5'
        >
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
