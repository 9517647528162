import { User } from '../../applications/core/_models'

export type BCID = string

export interface ProjectCreate {
  name: string
  description?: string
}

export enum BuildingAreaUnits {
  sqm = 'sqm',
  sqft = 'sqft',
}

export enum Jurisdiction {
  BC = 'BC',
  CA = 'CA',
}

export const UNIT_LABELS = {
  [BuildingAreaUnits.sqm]: 'm²',
  [BuildingAreaUnits.sqft]: 'ft²',
}

export interface BuildingCharacteristics {
  buildingArea?: number
  buildingAreaNormalized?: number
  buildingAreaUnits?: BuildingAreaUnits
  majorOccupancies?: string[]
  buildingHeight?: number
  numberOfStreets?: number
  sprinklered?: boolean
}

export interface ProjectUpdate {
  name?: string
  description?: string
  avatar?: string | null
  stage?: ProjectStage
}

export interface Building {
  id: string
  name: string
  characteristics: BuildingCharacteristics | null
}

export type BuildingUpdate = Partial<Omit<Building, 'id'>>

export enum ProjectStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  OVERDUED = 'OVERDUED',
  COMPLETED = 'COMPLETED',
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
}

export enum ProjectUserRole {
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
}

export interface ProjectUserCreate {
  userId: BCID
  role: ProjectUserRole
}

export interface BaseMetric {
  count: number
}

export interface DocumentMetric extends BaseMetric {
  numberOfDocuments: number
}

export enum ProjectStage {
  PRE_DESIGN = 'PRE_DESIGN',
  SCHEMATIC_DESIGN = 'SCHEMATIC_DESIGN',
  DESIGN_DEVELOPMENT = 'DESIGN_DEVELOPMENT',
  CONSTRUCTION_DOCUMENTS = 'CONSTRUCTION_DOCUMENTS',
  BIDDING_OR_NEGOTIATION = 'BIDDING_OR_NEGOTIATION',
  CONSTRUCTION_ADMINISTRATION = 'CONSTRUCTION_ADMINISTRATION',
}

export interface Project extends ProjectCreate {
  id: BCID
  jurisdiction: Jurisdiction
  progress: number
  status: ProjectStatus
  avatar: string | null
  stage: ProjectStage
  projectUsers: {
    id: BCID
    role: ProjectUserRole
    user: User
    createdAt: string
  }[]
  metrics: {
    errors: DocumentMetric
    warnings: DocumentMetric
    checkedDocuments: BaseMetric
  }
  buildingCharacteristics?: BuildingCharacteristics
}

export interface UploadFileLinkResponse {
  key: string
  link: string
}

export enum DocumentType {
  SPECIFICATION = 'SPECIFICATION',
  DRAWING = 'DRAWING',
  APPLICATION = 'APPLICATION',
  TEMPLATE = 'TEMPLATE',
  ISSUED_PERMIT = 'ISSUED_PERMIT',
}

export enum DocumentStatus {
  UPLOADED = 'UPLOADED',
  PROCESSED = 'PROCESSED',
}

export interface DocumentCreate {
  projectId: BCID
  filePath: string
  documentType: DocumentType
  fileType?: string
  originalFileName?: string
  fileSize: number
}

export interface Document extends DocumentCreate {
  id: BCID
  createdAt: string
  createdBy: string
  filePath: string
  status: DocumentStatus
  userCreatedBy: User
}

export enum Severity {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export interface ActionItem {
  id: BCID
  createdAt: string
  createdBy: BCID
  complianceRunId: BCID
  projectId: BCID
  buildingId?: BCID
  documentId?: BCID
  imageId?: BCID
  objectId?: BCID
  segmentId?: BCID
  complianceShapeId?: BCID
  lineId?: BCID
  type: string
  group: string
  severity: Severity
  title: string
  description?: string
}

export interface BBox {
  x: number
  y: number
  w: number
  h: number
}

export interface ImageEntity {
  id: BCID
  imageId: BCID
  properties: unknown
  createdByEmail: string
}

export enum ObjectClass {
  AC_DOOR = 'ac-door',
  AC_GRAB_BAR = 'ac-grab-bar',
  AC_PARKING_SPACE = 'ac-parking-space',
  AC_PARKING_SPACE_AB = 'ac-parking-space-ab',
  AC_PARKING_SPACE_EV = 'ac-parking-space-ev',
  AC_STAIR = 'ac-stair',
  AC_WATER_FOUNTAIN = 'ac-water-fountain',
  E_ELEVATOR = 'e-elevator',
  E_SWITCH = 'e-switch',
  FP_EXIT_SIGN = 'fp-exit-sign',
  FP_FIRE_DAMPER = 'fp-fire-damper',
  FP_SMOKE_DETECTOR = 'fp-smoke-detector',
  FP_SPEAKER = 'fp-speaker',
  FP_SPEAKER_STROBE = 'fp-speaker-strobe',
  FP_SPRINKLER_HEAD_APARTMENT = 'fp-sprinkler-head-apartment',
  FP_SPRINKLER_HEAD_COMMON = 'fp-sprinkler-head-common',
  FP_SPRINKLER_HEAD_SERVICE = 'fp-sprinkler-head-service',
  FP_VALVE = 'fp-valve',
  P_TOILET = 'p-toilet',
  X_COLUMN = 'x-column',
  X_PARKING_BUMPER = 'x-parking-bumper',
  X_PRECAST_STAIR = 'x-precast-stair',
  X_STOVE = 'x-stove',
  X_FRIDGE = 'x-fridge',
  X_OVEN = 'x-oven',
  X_DISHWASHER = 'x-dishwasher',
  X_MICROWAVE = 'x-microwave',
  X_WASHER = 'x-washer',
  X_DRY = 'x-dry',
  X_TV = 'x-tv',
  X_BED = 'x-bed',
  X_CHAIR = 'x-chair',
  X_STAND = 'x-stand',
  X_LAMP = 'x-lamp',
  X_DESK = 'x-desk',
  X_COUCH = 'x-couch',
  X_SINK = 'x-sink',
  X_SHOWER = 'x-shower',
  X_BATHTUB = 'x-bathtub',
  X_WINDOW = 'x-window',
  X_TABLE = 'x-table',
}

export enum ObjectSubClass {
  REGULAR = 'regular',
  EXIT = 'exit',
}

export interface ImageObjectProps extends BBox {
  class?: ObjectClass
  confidence?: number
  estimation_rate?: number
  sub_class?: ObjectSubClass
  type?: string
  door_swing?: string
}

export interface ImageObject extends ImageEntity {
  properties: ImageObjectProps
  calculated: {
    egressLevel?: number
    estimation_rate: number
    estimation: {
      [key in EstimationDivision]: {
        rate: number
        value: number
      }
    }
  }
}

export interface ImageElementProps extends BBox {
  class?: string
}

export interface ImageElement extends ImageEntity {
  properties: ImageElementProps
}

export interface ImageScaleProps extends BBox {
  scale?: string
}

export interface ImageScale extends ImageEntity {
  properties: ImageScaleProps
}

export interface ImageLabelProps extends BBox {
  label?: string
}

export interface ImageLabel extends ImageEntity {
  properties: ImageLabelProps
}

interface GeoJSONFeature {
  type: 'Feature'
  geometry: {
    type: 'Polygon'
    coordinates: [number, number][][]
  }
}

interface SegmentProps extends GeoJSONFeature {
  properties?: {
    occupancy_type?: string
    occupancy_load?: number | null
    clearance_type?: string
    class?: string
    estimation?: {
      [key: string]: string | undefined
    }
    custom_estimation_rate?: {
      [key: string]: number | null | undefined
    }
  }
}

interface WallProps extends GeoJSONFeature {
  properties?: {
    estimation?: {
      height?: number
      [key: string]: string | number | undefined
    }
    custom_estimation_rate?: {
      [key: string]: number | undefined
    }
  }
}

export interface ImageSegment extends ImageEntity {
  occupancyType: string
  properties: SegmentProps
  calculated?: {
    areaFt?: number
    areaM?: number
    occupancyLoad?: number
    egressPathList: [number, number][][]
    estimation: {
      [key in EstimationDivision]: {
        [key: string]: {
          rate: number
          value: number
        }
      }
    }
  }
}

interface BBoxGeometry {
  x: number
  y: number
  w: number
  h: number
}

interface CircleGeometry {
  x: number
  y: number
  r: number
}

interface LineGeometry {
  coords: [number, number][]
}

interface CleareanceBox {
  type: 'bbox'
  geometry: BBoxGeometry
  properties?: {
    widthM?: number
    widthFt?: number
    depthM?: number
    depthFt?: number
    areaM?: number
    areaFt?: number
    description?: string
  }
}

interface CleareanceCircle {
  type: 'circle'
  geometry: CircleGeometry
  properties?: {
    areaM?: number
    areaFt?: number
    diametrM?: number
    diametrFt?: number
    description?: string
  }
}

interface CleareanceLine {
  type: 'line'
  geometry: LineGeometry
  properties?: {
    lengthM: number
    lengthFt: number
    description?: string
  }
}

interface ClearanceCollection {
  type: 'collection'
  list: (CleareanceBox | CleareanceCircle | CleareanceLine)[]
  properties?: {
    description?: string
  }
}

export interface ComplianceShape extends ImageEntity {
  properties: ClearanceCollection | CleareanceBox | CleareanceCircle | CleareanceLine
}

export interface ImageWall extends ImageEntity {
  properties: WallProps
  calculated?: {
    lengthFt?: number
    lengthM?: number
    estimation: {
      [key in EstimationDivision]?: {
        [key: string]: {
          rate: number
          value: number
        }
      }
    }
  }
}

// export interface FootprintProps extends GeoJSONFeature {
//   properties?: {
//     estimation: {
//       [key: string]: string
//     }
// }

export interface ImageFootprint extends ImageEntity {
  properties: GeoJSONFeature
  calculated?: {
    areaFt?: number
    areaM?: number
    estimation: {
      [key in EstimationDivision]?: {
        rate: number
        value: number
      }
    }
  }
}

export enum LineClass {
  EGRESS_PATH = 'egress_path',
}

export interface LineProps {
  class: LineClass
  path: [number, number][]
}

export interface ImageLine extends ImageEntity {
  properties: LineProps
  calculated: {
    lengthFt: number
    lengthM: number
  }
}

export interface EstimationSummaryItem {
  label: string
  quantity: number
  unitRate: number
  total: number
}

export interface EstimationSummaryDivision {
  label: string
  total: number
  items: EstimationSummaryItem[]
}

export interface EstimationSummaryGroup {
  label: string
  divisions: EstimationSummaryDivision[]
}

export interface EstimationSummary {
  total: number
  groups: EstimationSummaryGroup[]
}

export interface ImageSummary {
  totalOccupant?: {
    label: string
    value: string | number
  }
  estimation?: EstimationSummary
}

export enum RatioUnits {
  M = 'M',
  FT = 'FT',
}

export interface DocumentImage {
  id: BCID
  pageNumber: number
  filePath: string
  ratio: number
  ratioUnit: RatioUnits
}

export type DocumentImageUpdate = Partial<Omit<DocumentImage, 'id'>>

export type ImageSegmentCreate = Omit<ImageSegment, 'id' | 'createdByEmail'>
export type ImageSegmentUpdate = Partial<ImageSegmentCreate>

export type ImageObjectCreate = Omit<ImageObject, 'id' | 'createdByEmail' | 'calculated'>
export type ImageObjectUpdate = Partial<ImageObjectCreate>

export type ImageElementCreate = Omit<ImageElement, 'id' | 'createdByEmail'>
export type ImageElementUpdate = Partial<ImageElementCreate>

export type ImageScaleCreate = Omit<ImageScale, 'id' | 'createdByEmail'>
export type ImageScaleUpdate = Partial<ImageScaleCreate>

export type ImageLabelCreate = Omit<ImageLabel, 'id' | 'createdByEmail'>
export type ImageLabelUpdate = Partial<ImageLabelCreate>

export type ImageWallCreate = Omit<ImageWall, 'id' | 'createdByEmail'>
export type ImageWallUpdate = Partial<ImageWallCreate>

export type ImageLineCreate = Omit<ImageLine, 'id' | 'createdByEmail'>
export type ImageLineUpdate = Partial<ImageLineCreate>

export type ImageFootprintCreate = Omit<ImageFootprint, 'id' | 'createdByEmail'>
export type ImageFootprintUpdate = Partial<ImageFootprintCreate>

export interface ComplianceRun {
  id: BCID
  createdAt: string
  createdBy: BCID
  projectId: BCID
  status: string
}

export enum EstimationSegmentSpaceType {
  LOUNGE = 'Lounge',
  ASSEMBLY_SPACE = 'Assembly Space',
  RESTAURANT = 'Restaurant',
  RETAIL = 'Retail',
  OFFICE = 'Office',
  AMENITY = 'Amenity',
  RESIDENTIAL = 'Residential',
  GYM = 'Gym',
  LOCKER_ROOM = 'Locker Room',
  STORAGE = 'Storage',
  KITCHEN = 'Kitchen',
  PARKADE = 'Parkade',
  CORRIDOR = 'Corridor',
  SERVICE_SPACE = 'Service Space',
}

export enum EstimationObjectSpaceType {
  TOILET = 'p-toilet',
  GRAB_BAR = 'ac-grab-bar',
  ELEVATOR = 'e-elevator',
}

export enum EstimationWallSpaceType {
  WALL = 'wall',
}

export enum EstimationItemType {
  SEGMENT = 'SEGMENT',
  OBJECT = 'OBJECT',
  WALL = 'WALL',
}

export enum EstimationDivision {
  DIVISION_3 = 'Division 3 - Concrete',
  DIVISION_5 = 'Division 5 - Metals',
  DIVISION_6 = 'Division 6 - Woods, Plastics, and Composites',
  DIVISION_7 = 'Division 7 - Thermal and Moisture Protection',
  DIVISION_8 = 'Division 8 - Openings',
  DIVISION_9 = 'Division 9 - Finishes',
  DIVISION_11 = 'Division 11 - Equipment',
  DIVISION_12 = 'Division 12 - Furnishings',
  DIVISION_14 = 'Division 14 - Conveying Equipment',
  DIVISION_22 = 'Division 22 - Plumbing',
}

export interface EstimationPriceUpsert {
  division: EstimationDivision
  itemType?: EstimationItemType
  className?: string
  value?: number
}

export interface EstimationPrice extends EstimationPriceUpsert {
  projectId: BCID
  itemType: EstimationItemType
  created_by: BCID
}
