/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx'
import { FC } from 'react'

import { useLayout } from '../core'

const Footer: FC = () => {
  const {
    classes,
    config: {
      footer: { display },
    },
  } = useLayout()

  if (!display) {
    return null
  }
  return (
    <div className={'footer py-4 d-flex flex-lg-column'} id='kt_footer'>
      {/*begin::Container*/}
      <div
        className={clsx(
          classes.footerContainer,
          'd-flex flex-column flex-md-row align-items-center justify-content-between',
        )}
      >
        {/*begin::Copyright*/}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-1'>{new Date().getFullYear()}&copy;</span>
          <a
            href='https://buildcheck.ai'
            target='_blank'
            className='text-gray-800 text-hover-primary'
          >
            BuildCheck AI, Inc.
          </a>
        </div>
        {/*end::Copyright*/}

        {/*begin::Menu*/}
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <a href='https://buildcheck.ai/about' target='_blank' className='menu-link px-2'>
              About
            </a>
          </li>

          <li className='menu-item'>
            <a href='https://buildcheck.ai/support' target='_blank' className='menu-link px-2'>
              Support
            </a>
          </li>
        </ul>
        {/*end::Menu*/}
      </div>
      {/*end::Container*/}
    </div>
  )
}

export { Footer }
