import { Box, Checkbox, Link, Typography, CheckboxProps } from '@mui/material'
import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

export const AcceptTerms: FC<CheckboxProps> = ({ ...checkboxProps }) => {
  const [searchParams] = useSearchParams()
  const location = window.location

  const termsUrl = 'https://buildcheck.ai/BuildCheck_Terms_and_Conditions.pdf'
  const privacyUrl = 'https://buildcheck.ai/BuildCheck_Privacy_Policy.pdf'
  const product = 'BUILDCHECK’s'

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox color='primary' {...checkboxProps} />
        <Typography variant='body1' fontWeight={500}>
          I agree to {product}{' '}
          <Link tabIndex={-1} href={termsUrl} target='_blank' rel='noreferrer'>
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link tabIndex={-1} href={privacyUrl} target='_blank' rel='noreferrer'>
            Privacy Policy
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  )
}
